/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, SideBySide, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-loss/otitis-media/'], ['en', 'https://www.hear.com/resources/hearing-loss/otitis-media/'], ['en-US', 'https://www.hear.com/resources/hearing-loss/otitis-media/'], ['en-CA', 'https://ca.hear.com/useful-knowledge/otitis-media/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "middle-ear-infection-otitis-media",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#middle-ear-infection-otitis-media",
    "aria-label": "middle ear infection otitis media permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Middle ear infection (otitis media)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-impaired-phone-hero.png",
    alt: "Otitis Externa Background image",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-diagnosis-and-treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-diagnosis-and-treatment",
    "aria-label": "symptoms diagnosis and treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms, diagnosis and treatment"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Definition: an acute middle ear infection (otitis media acuta) is a condition often triggered by a cold"), ". Viruses travel from the nasopharyngeal cavity via the auditory tube into the middle ear, and the mucous membrane of the tympanum gets inflamed. The result is a change in pressure conditions, which impairs the vibration ability of the eardrum and the quality of sound transmission."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms",
    "aria-label": "symptoms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Middle ear infections are generally accompanied by severe earaches. In addition, there are other symptoms and after-effects that point to a otitis media acuta:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Feeling of weakness"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Redness and swelling of the mastoid process"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/pressure/",
    className: "c-md-a"
  }, "Sensation of pressure and fullness in the ear")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Dizziness"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Fever"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Headache"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/hearing-loss/conductive/",
    className: "c-md-a"
  }, "Conductive hearing loss")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ringing in the ears (tinnitus)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Fluid behind the eardrum"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tear or hole in the eardrum"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Discharge from the ear (otorrhoea)"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosis",
    "aria-label": "diagnosis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosis"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/otitis-media.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ear, nose and throat specialist examines your eardrum with an otoscope (ear speculum with light source and magnifier) and subsequently determines if and what kind of treatment is necessary. ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "A hearing test performed"), " for the purpose of diagnosing an acute middle ear infection would first reveal conductive hearing loss. Immune-compromised patients and those who do not respond to antibiotics treatment are subjected to a smear test and bacteriological testing, which allows for the identification of the specific pathogen and hence the use of targeted antibiotics. Prior to the therapy, conditions causing earaches, like diabetes or allergies have to be checked.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How does a smear test work with middle ear infection?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the case of a middle ear infection, a smear of the middle ear mucosa is performed with a sterile swab, small bristle or spatula. This will be used to identify the causative pathogen."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How are antibiotics therapies done with middle ear infection?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Middle ear infections are frequently treated with antibiotics. We differentiate between therapies that kill the pathogen (bactericidal antibiotics) and those that inhibit the pathogen. Such a therapy generally takes seven days, but should always be under supervision of the ENT-physician."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment-of-middle-ear-infection",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment-of-middle-ear-infection",
    "aria-label": "treatment of middle ear infection permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment of middle ear infection"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At first, the doctor will ", React.createElement(_components.strong, null, "treat the symptoms and not the cause of the middle ear infection"), " over the first 1-2 days. This is standard practice in medicine. During this step, decongestants in the form of nasal sprays and drops or pain medication like Paracetamol are prescribed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Non-treatment can have the following after-effects:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Paralysis of facial muscles"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Inflammation of the mastoid process"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Infection of the inner ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Meningitis"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Permanent hearing loss"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In addition to pain management, ", React.createElement(_components.strong, null, "antibiotics are often prescribed"), ". This is a necessary step if there is no improvement after 7-10 days of symptom treatment. In some cases, the doctor has to perform a small incision in the eardrum. This is done under local anesthesia to allow the accumulated fluid to drain. Immediately after the procedure, the pain will subside and there will be fast relief. Consequently, possible headaches and other symptoms, such as pressure and fever, will also be alleviated."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "household-remedies-against-otitis-media",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#household-remedies-against-otitis-media",
    "aria-label": "household remedies against otitis media permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Household remedies against otitis media"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are several self-help therapies that can bring relief with a middle ear infection. A very popular household remedy involves the ", React.createElement(_components.strong, null, "use of onions. They possess anti-bacterial properties and finely chopped and wrapped in cloth they are pressed against the affected ear. Potatoes can be used in the same fashion."), " However, potatoes have to be boiled and mashed first. A third option involves the use of ", React.createElement(_components.strong, null, "an infrared lamp shining on the ear"), ". Hereby one has to use caution not to overheat."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With acute symptoms, however, a visit with the ENT specialist is strongly advised. Your ENT specialist will thoroughly examine your ears and can check your hearing by performing a hearing test."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "complications",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#complications",
    "aria-label": "complications permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Complications"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Complications with middle ear infections are varied. In general, ", React.createElement(_components.strong, null, "complications with ear infections are relatively rare"), ", but if they occur, they can can severely impact health. A common complication is the inflammation of the mastoid process, which is located behind the outer ear at the temporal bone. Doctors also use the term acute mastoiditis, characterized by redness and variable swelling of the skin at this location. Frequent middle ear infections bear the risk of turning into a chronic condition. This can lead to scarring of the eardrum, and the ossicles can suffer damage as well. These three tiny bones only measure a few millimeters and can become misshapen or even destroyed due to a chronic middle ear infection. Both can in turn result in permanent hearing loss, which is why the consultation of an ENT-specialist should be imperative with the first signs of symptoms."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Inflammation of the mastoid process (mastoiditis)")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The mastoid process is a cone-shaped bone behind the outer ear. It can get inflamed due to a badly healing middle ear infection, or if the infection is not healing at all. The symptoms are similar to those caused by an otitis media acuta, except they are generally more severe."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "middle-ear-infection-and-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#middle-ear-infection-and-hearing-aids",
    "aria-label": "middle ear infection and hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Middle ear infection and hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Chronic middle ear infections can ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "lead to hearing loss")), ". In these situations, ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids can help you regain your quality of life"), ". However, during an episode of a middle ear infection, wearers of hearing aids should consider the following: ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "In-the-ear devices"), " and ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte/",
    className: "c-md-a"
  }, "behind-the-ear hearing aids"), " with closed otoplastics (ear cap) are not recommended with chronic middle ear infection. A behind-the-ear device with “open hearing aid access”, leading a thin tube to the inner ear, is recommended under these circumstances. This keeps the ear sufficiently aerated. Hearing aids, after all, cannot cause a middle ear infection, because the infection usually travels from the mouth and throat. When a chronic middle ear infection leads to hearing loss, hearing aids help to compensate this impairment and allow participating in life again. However, ", React.createElement(_components.strong, null, "only models providing sufficient aeration of the ear should be worn in the course of a middle ear infection.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "reasons-for-the-development-of-a-middle-ear-infection",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#reasons-for-the-development-of-a-middle-ear-infection",
    "aria-label": "reasons for the development of a middle ear infection permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Reasons for the development of a middle ear infection"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Otitis media acuta can develop in three different ways:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An infection in the nose and throat region travels over to the auditory tube into the middle ear."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "In some cases, the infection, i.e. pathogens, enter from the outside directly to the middle ear due to a defect in the eardrum."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "In very rare cases, pathogens can travel to the middle ear via the bloodstream."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Generally, there are two causes that can contribute to the development of middle ear infections:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A continuous aeration of the middle ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "An acute or prior infection"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The aeration malfunction is almost always caused by a deficiency in the aeration of the tube. Prior infections are mostly viral and bacterial infections of the upper respiratory tract."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How do pathogenic agents get into the bloodstream?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If there already an infection of the middle ear present, the bacterial pathogens and toxins can spread to the adjacent bone tissue. They can get into the bloodstream and possibly reach the brain."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-types-of-pathogens-can-cause-a-middle-ear-infection",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-types-of-pathogens-can-cause-a-middle-ear-infection",
    "aria-label": "what types of pathogens can cause a middle ear infection permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What types of pathogens can cause a middle ear infection?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The following bacteria and viruses are causative agents for middle ear infections:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Streptococcus pneumoniae: Can cause pneumonia"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Haemophilus influenzae: Can cause feverish inflammation of nose and throat area"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Beta-hemolytic streptococci: Can lead to sepsis, meningitis, or pneumonia, especially in preterm babies"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In rare cases it is possible that influenza viruses are the causative agents for an acute middle ear infection. Here the adopted term is flu-otitis, which is the most dangerous form of otitis media acuta, since a possible outcome is deafness of the ear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Additional information")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The umbrella term “acute middle ear infection” can take the following forms:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Flu-otitis")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An inflammation of the middle ear caused by influenza viruses. It is counted among the most severe forms of otitis media acuta and can even lead to complete deafness in the afflicted ear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Scarlet fever- and measles-otitis")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Arises through transmission of the bacteria into the ear via the bloodstream. Often, this condition involves a cell-destructive inflammation of the mucosa and inflammatory decomposition of the eardrum."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Mucosa-otitis")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This special condition leads to minor changes in the eardrum, the result of which is the decomposition of bone tissue in the mastoid process and even meningitis in severe cases."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Infant-otitis")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A middle ear infection in infants is frequently associated with an infection of the upper respiratory tract, which can be caused by the common cold or the flu. The course of his infection is comparable with that of an ordinary otitis media acuta."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "middle-ear-infection-in-children",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#middle-ear-infection-in-children",
    "aria-label": "middle ear infection in children permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Middle ear infection in children"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Children up to 6 years of age are most often affected by otitis media acuta"), ". During their first year of life, 50% of infants contract a middle ear infection. By the time they are three years old, this number jumps to 80%. The greatest risk hereby is that the children’s complaints are not taken seriously and the condition is protracted. This can quickly lead to a permanent hearing loss in the child. The following are important signs that your child is affected:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Increasing body temperature"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Fever higher than 102° Fahrenheit"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Child is constantly touching the ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Pain in the stomach area"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With infants and toddlers, who more frequently have middle ear infections, an enlargement of the tonsils can occur. In such a situation, the following signs can determine whether the tonsils should be removed:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Problems breathing through the nose"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Accumulation of fluid behind the eardrum"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Several middle ear infections within a year"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "In the medical field, doctors use the term adenoitis for an inflammation of the tonsils."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Protect children and babies")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Children of parents who smoke have a higher incidence for acute middle ear infections.1 Also, children who have had previous instances of infection have an increased risk for a relapse. On the other hand, it has been shown that infants who were breastfed by their mothers are significantly less often afflicted by middle ear infections."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
